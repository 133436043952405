

/* Create three equal columns that floats next to each other */
.column_form {
  width: 20.00%;
  padding: 2%;
}

.column_images {
  width: 40.00%;
  padding: 2%;
}


/* Clear floats after the columns */
.row {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 100px);
  margin-bottom: 20px;
}

button {
  background-color: white; 
  color: black; 
  border: 2px solid #f44336;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
}

button:hover {
  background-color: #f44336;
  color: white;
}