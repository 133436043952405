* {
  margin: 0px;
  padding: 0px;
  font-family: 'Arial', sans-serif !important;
}


.main_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  height: 95vh;
}

.message {
  text-align: justify;
  align-items: center;
  justify-content: center;
  width: 70%;
  height: 50%;
  margin: auto;
  font-size: large;
}

/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 400px;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;

  height: 300px;
  width: 100%;
  border-radius: 40px;
}

/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 20px;
  width: 20px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}
