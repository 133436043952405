.dashboard_container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  height: 100vh;
}

.scrollable {
  max-height: 20;
  overflow: scroll;
  overflow-y: scroll;
}

.FixedHeightContainer
{
  float:right;
  height: 500px;
  width:500px; 
  padding:3px; 
}
.Content
{
  height:224px;
  overflow:auto;
  background:#fff;
}

.record {
  display: flex;
  justify-content: space-between;

}


table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {background-color: #f2f2f2;}


tr:hover {background-color: coral;}

th {
  background-color: lightskyblue;
  color: black;
}

.column_dashboard {
    width: 50%;
    padding: 2%;
}
  
  
/* Clear floats after the columns */
.row_dashboard {
    display: flex;
    justify-content: space-between;
    height: calc(100% - 100px);
    margin-bottom: 20px;
}

.heighttext{
    height:30px
}

/* ******** */
.fixed_header {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
}
.fixed_header tbody {
    display: block;
    width: 100%;
    overflow: auto;
    height: 400px;
}
.fixed_header thead tr {
    display: block;
}
.fixed_header thead {
    background: black;
    color: #fff;
}
.fixed_header th,
.fixed_header td {
    padding: 5px;
    text-align: left;
    width: 100%;
}
.button3 {
  background-color: white; 
  color: black; 
  border: 2px solid #f44336;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius:10px;
}

.button3:hover {
  background-color: #f44336;
  color: white;
}